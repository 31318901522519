import { generateApiHooks } from "./generateApiHooks";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const createApi = <Repos extends Record<string, Record<string, (...args: any[]) => any>>>(repos: Repos) => {
	type GeneratedApi = {
		[RepoKey in keyof Repos]: ReturnType<typeof generateApiHooks<Repos[RepoKey]>>;
	};

	const apiEntries = Object.entries(repos).map(([key, repo]) => {
		return [key, generateApiHooks(repo)];
	});

	const api: GeneratedApi = Object.fromEntries(apiEntries) as GeneratedApi;

	return api;
};
