// import { getServerPageFilters } from '@/data/__generated__/api/ssr';
import { getServerPageCategoryFilters } from "@/data/__generated__/api/ssr";
import { useCategoryFiltersQuery, useFiltersByManufacturerQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const PropertiesRepo = {
	CategoryFilters: RepoBuilder.createClientRequest({
		hook: useCategoryFiltersQuery,
	}),

	FiltersByManufacturer: RepoBuilder.createClientRequest({
		hook: useFiltersByManufacturerQuery,
	}),

	CategoryFiltersAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCategoryFilters,
	}),
};

export { PropertiesRepo };
