import { usePaymentLazyQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const PaymentRepo = {
	PaymentUrlLazy: RepoBuilder.createLazyQuery({
		hook: usePaymentLazyQuery,
	}),
};

export { PaymentRepo };
