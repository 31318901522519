import {
	getServerPageManufacturer,
	// getServerPageManufacturer,
	getServerPageManufacturers,
	getServerPageManufacturersGroups,
} from "@/data/__generated__/api/ssr";
import {
	useManufacturerQuery,
	useManufacturersGroupsQuery,
	useManufacturersQuery,
} from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const ManufacturerRepo = {
	ManufacturersGroup: RepoBuilder.createClientRequest({
		hook: useManufacturersGroupsQuery,
	}),

	ManufacturersList: RepoBuilder.createClientRequest({
		hook: useManufacturersQuery,
	}),

	Manufacturer: RepoBuilder.createClientRequest({
		hook: useManufacturerQuery,
	}),

	ManufacturersGroupAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageManufacturersGroups,
	}),

	ManufacturersListAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageManufacturers,
	}),

	ManufacturerAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageManufacturer,
	}),
};

export { ManufacturerRepo };
