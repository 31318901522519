import 'cross-fetch/polyfill';
import type { IncomingMessage } from 'node:http';
import type { NormalizedCacheObject } from '@apollo/client';
import type {
  NextApiRequestCookies,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore This path is generated at build time and conflicts otherwise
  // eslint-disable-next-line import/no-unresolved
} from 'next-server/server/api-utils';

import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import merge from 'deepmerge';

import { GRAPH_URI } from '@/constants';

export type ApolloClientContext = {
  req?: {
    cookies: NextApiRequestCookies;
  } & IncomingMessage;
};

let apolloClient: NextSSRApolloClient<NormalizedCacheObject>;

export const createApolloClient = (initialState?: NormalizedCacheObject) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Path: ${path}, Location: ${locations}`
        )
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({
    uri: GRAPH_URI,
    fetchOptions: { cache: 'no-store' },
  });

  return new NextSSRApolloClient({
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            errorLink,
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
    cache: new NextSSRInMemoryCache({
      typePolicies: {
        ManufaturerExtendedResponse: {
          merge(existing = [], incoming) {
            if (!incoming || !incoming.length) return existing;

            return [...existing, ...incoming];
          },
        },
        ListCollectionsResponse: {
          fields: {
            collections: {
              merge(existing = [], incoming) {
                if (!incoming || !incoming.length) return existing;

                return [...existing, ...incoming];
              },
            },
          },
        },
        ItemListType: {
          fields: {
            items: {
              merge(existing = [], incoming) {
                if (!incoming || !incoming.length) return existing;

                return [...existing, ...incoming];
              },
            },
          },
        },
        CategoryProductsResponse: {
          fields: {
            products: {
              merge(existing = [], incoming) {
                if (!incoming || !incoming.length) return existing;

                return [...existing, ...incoming];
              },
            },
          },
        },
        ListManufacturersResponse: {
          fields: {
            manufacturerList: {
              merge(existing = [], incoming) {
                if (!incoming || !incoming.length) return existing;

                return [...existing, ...incoming];
              },
            },
          },
        },
        PropertyGroupResponse: {
          fields: {
            categoryFilters: {
              merge(existing = [], incoming) {
                if (!incoming || !incoming.length) return existing;

                return [...existing, ...incoming];
              },
            },
          },
        },
        Query: {
          fields: {
            collections: {
              keyArgs: ['input', ['categoryLft', 'categoryRgt', 'filters']],
            },
            items: {
              keyArgs: [
                'input',
                ['categoryLft', 'categoryRgt', 'filters', 'keyword'],
              ],
            },
            collectionsByManufacturer: {
              keyArgs: [
                'input',
                ['manufacturerSlug', 'filters', 'onlyInStock'],
              ],
            },
            itemsByManufacturer: {
              keyArgs: [
                'input',
                ['manufacturerSlug', 'filters', 'onlyInStock'],
              ],
            },
            productsByCollection: {
              keyArgs: ['input', ['collectionUrl']],
            },
            manufacturers: {
              keyArgs: ['input', ['firstLetter', 'country']],
            },
            categoryProducts: {
              keyArgs: ['input', ['categoryId', 'filters', 'keyword']],
            },
            categoryFilters: {
              keyArgs: ['input', ['categoryLft', 'categoryRgt', 'categoryUrl']],
            },
            itemsByCollection: {
              keyArgs: ['input', ['collectionUrl']],
            },
          },
        },
      },
    }).restore(initialState || {}),
  });
};

export const getApolloClient = (initialState?: NormalizedCacheObject) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();

    const data = merge(initialState, existingCache);

    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};
