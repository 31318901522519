import { useSuggestionLazyQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const SuggestionRepo = {
	Suggestion: RepoBuilder.createLazyQuery({
		hook: useSuggestionLazyQuery,
	}),
};

export { SuggestionRepo };
