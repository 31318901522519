import { getServerPageLinks } from "@/data/__generated__/api/ssr";
import { useLinksQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const LinkRepo = {
	HomeLinks: RepoBuilder.createClientRequest({
		hook: useLinksQuery,
	}),

	HomeLinksAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageLinks,
	}),
};

export { LinkRepo };
