import { useCreateDiscountRequestMutation } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const DiscountRequestRepo = {
	CreateDiscountRequest: RepoBuilder.createMutation({
		hook: useCreateDiscountRequestMutation,
	}),
};

export { DiscountRequestRepo };
