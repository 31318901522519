import { getServerPageOrder } from "@/data/__generated__/api/ssr";
import { useCreateOrderMutation, useOrderQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const OrderRepo = {
	CreateOrder: RepoBuilder.createMutation({
		hook: useCreateOrderMutation,
	}),

	Order: RepoBuilder.createClientRequest({
		hook: useOrderQuery,
	}),

	OrderAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageOrder,
	}),
};

export { OrderRepo };
