import { getServerPageActiveCategory, getServerPageCategories } from "@/data/__generated__/api/ssr";
import { useActiveCategoryQuery, useCategoriesQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const CategoryRepo = {
	Categories: RepoBuilder.createClientRequest({
		hook: useCategoriesQuery,
	}),

	ActiveCategory: RepoBuilder.createClientRequest({
		hook: useActiveCategoryQuery,
	}),

	CategoriesAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCategories,
	}),

	ActiveCategoryAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageActiveCategory,
	}),
};

export { CategoryRepo };
