import {
	getServerPageCollection,
	getServerPageCollections,
	getServerPageNewCollections,
	getServerPageSliderCollections,
} from "@/data/__generated__/api/ssr";
import {
	useCollectionsByLabelQuery,
	useCollectionsByManufacturerQuery,
	useCollectionsQuery,
	useNewCollectionsQuery,
	useSliderCollectionsQuery,
} from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const CollectionRepo = {
	Collections: RepoBuilder.createClientRequest({
		hook: useCollectionsQuery,
	}),

	Collection: RepoBuilder.createClientRequest({
		hook: useCollectionsQuery,
	}),

	SliderCollections: RepoBuilder.createClientRequest({
		hook: useSliderCollectionsQuery,
	}),

	NewCollections: RepoBuilder.createClientRequest({
		hook: useNewCollectionsQuery,
	}),

	CollectionsByLabel: RepoBuilder.createClientRequest({
		hook: useCollectionsByLabelQuery,
	}),

	CollectionsByManufacturer: RepoBuilder.createClientRequest({
		hook: useCollectionsByManufacturerQuery,
	}),

	CollectionAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCollection,
	}),

	CollectionsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCollections,
	}),

	SliderCollectionsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageSliderCollections,
	}),

	NewCollectionsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageNewCollections,
	}),
};

export { CollectionRepo };
