import { getServerPage, getServerPageSeo } from "@/data/__generated__/api/ssr";
import { usePageQuery, usePageSeoQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const PageRepo = {
	PageSeo: RepoBuilder.createClientRequest({
		hook: usePageSeoQuery,
	}),

	Page: RepoBuilder.createClientRequest({
		hook: usePageQuery,
	}),

	PageSeoAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageSeo,
	}),

	PageAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPage,
	}),
};

export { PageRepo };
