import type { HookReturnType } from "@/types";
import type { OperationVariables, QueryResult } from "@apollo/client";

export function createRepoReturn<TData, TVariables extends OperationVariables>(
	result: QueryResult<TData, TVariables>
): HookReturnType<TData, TVariables> {
	const { data, error, loading, fetchMore, refetch } = result;
	return {
		data,
		error,
		loading,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		fetchMore: fetchMore as any,
		refetch,
	};
}
