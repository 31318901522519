import { getServerPageSiteMessage } from "@/data/__generated__/api/ssr";
import { useSiteMessageQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const OptionRepo = {
	SiteMessage: RepoBuilder.createClientRequest({
		hook: useSiteMessageQuery,
	}),

	SiteMessageAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageSiteMessage,
	}),
};

export { OptionRepo };
