import { getServerPageArticle, getServerPageArticles } from "@/data/__generated__/api/ssr";
import { useArticleQuery, useArticlesQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const ArticlesRepo = {
	Articles: RepoBuilder.createClientRequest({
		hook: useArticlesQuery,
	}),

	ArticlesAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageArticles,
	}),

	Article: RepoBuilder.createClientRequest({
		hook: useArticleQuery,
	}),

	ArticleAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageArticle,
	}),
};

export { ArticlesRepo };
