import { getServerPageCountries } from "@/data/__generated__/api/ssr";
import { useCountriesQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const CountryRepo = {
	Countries: RepoBuilder.createClientRequest({
		hook: useCountriesQuery,
	}),

	CountriesAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageCountries,
	}),
};

export { CountryRepo };
