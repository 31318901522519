import { getServerPageRedirects } from "@/data/__generated__/api/ssr";
import { useRedirectsQuery } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const RedirectRepo = {
	Redirects: RepoBuilder.createClientRequest({
		hook: useRedirectsQuery,
	}),

	RedirectsAsync: RepoBuilder.createServerRequest({
		ssrFunction: getServerPageRedirects,
	}),
};

export { RedirectRepo };
