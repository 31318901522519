// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const generateApiHooks = <R extends Record<string, (...args: any[]) => any>>(repo: R) => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	type ApiHookType<T extends (...args: any[]) => any> = (...args: Parameters<T>) => ReturnType<T>;

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const apiHooks: { [K in keyof R]: ApiHookType<R[K]> } = {} as any;

	(Object.keys(repo) as Array<keyof R>).forEach((method) => {
		apiHooks[method] = (...args: Parameters<R[typeof method]>) => repo[method]!(...args);
	});

	return apiHooks;
};
