import { useLoginMutation } from "@/data/__generated__/generated";
import RepoBuilder from "@/data/helpers/repoBuilder";

const UserRepo = {
	Login: RepoBuilder.createMutation({
		hook: useLoginMutation,
	}),
};

export { UserRepo };
