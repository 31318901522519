import type { SelectedFilters } from "@/types";

import { ProductStatus, ThemeColor } from "@/types";

export const IMAGE_URL = "https://cms.xplit.ru/uploads";

export const SLIDER_DEFAULT_TAKE = 6;
export const MAIN_DOMAIN = "https://www.xplit.ru";
// export const GRAPH_URI = 'http://localhost:8080/graphql';
export const GRAPH_URI = "https://api.xplit.ru/graphql";
export const CMS_URL = "https://cms.xplit.ru";

export const PAGINATION_ITEMS_PER_PAGE = 45;
export const MANUFACTURERS_LIMIT = 36;
export const PAGINATION_MAX_PAGES_TO_SHOW = 8;
export const ORDER_S_ID_COOKIE = "S_ID";

export const FILTERS_DEFAULT_COUNT = 5;

export const ORDER_STATUS = {
	"status.pending": "Ожидает обработки",
	"status.processing": "Обрабатывается",
	"status.approved": "Одобрен",
	"status.waiting": "Ожидает оплаты",
	"status.waitforpay": "Оплата обрабатывается",
	"status.paid": "Оплачен",
	"status.packaged": "Ожидает отправки",
	"status.shipped": "Отправлен",
	"status.delivered": "Доставлен",
	"status.done": "Выполнен",
	"status.canceled": "Отменен",
};

export enum OrderStatus {
	approved = "status.approved",
	canceled = "status.canceled",
	delivered = "status.delivered",
	done = "status.done",
	packaged = "status.packaged",
	paid = "status.paid",
	pending = "status.pending",
	processing = "status.processing",
	shipped = "status.shipped",
	waitforpay = "status.waitforpay",
	waiting = "status.waiting",
}

export const FILTER_TITLES = {
	Цвет: "color",
	Размер: "size",
	Назначение: "purpose",
	Поверхность: "surface",
	"Тип помещения": "roomType",
	Форма: "shape",
	Бренд: "brand",
	Страна: "country",
};

export const initialFilters: SelectedFilters = {
	color: [],
	size: [],
	purpose: [],
	surface: [],
	roomType: [],
	shape: [],
	brand: [],
	country: [],
};

export const LABELS_COLORS = {
	Популярная: ThemeColor.accentYellow,
	Распродажа: ThemeColor.accentRed,
	Архив: ThemeColor.darkGlass,
	"Хит продаж": ThemeColor.accentYellow,
	"Образец Север": ThemeColor.accentPurple,
	"Образец Восток": ThemeColor.accentBlue,
};

export const FILTERS_ORDERING: {
	[title: string]: { defaultExpanded: boolean; priority: number };
} = {
	Цвет: { priority: 1, defaultExpanded: true },
	Размер: { priority: 2, defaultExpanded: true },
};

export const HEADER_ADRESSES = [
	{
		href: "tel:+74952368000",
		phoneString: "+7 (495) 236-80-00",
		region: "Север, Москва",
		street: "ул. Белозерская, 23Г",
		mapUrl: "https://yandex.ru/maps/-/CDCpe8pd",
	},
	{
		href: "tel:+74953241111",
		phoneString: "+7 (495) 324-11-11",
		region: "Восток, Москва",
		street: "ул. Салтыковская, 7Г, ТЦ Авалон",
		mapUrl: "https://yandex.ru/maps/-/CDGc6Ll3",
	},
];

export const HEADER_CONTACTS = [
	{
		href: "tel:88007079025",
		icon: "contacts",
		id: "phone",
		text: "8 (800) 707-90-25",
	},
	{
		href: "mailto:zakaz@xplit.ru",
		icon: "mail",
		id: "mail",
		text: "zakaz@xplit.ru",
	},
	{
		href: "https://wa.me/79251601043",
		icon: "whatsup",
		id: "whatsapp",
		text: "Whatsapp",
	},
];

export const NAV_MENU_ITEMS = [
	{
		href: "/order",
		title: "Заказ и оплата",
	},
	{
		href: "/delivery",
		title: "Доставка",
	},
	{
		href: "/about",
		title: "О нас",
	},
	{
		href: "/publications",
		title: "Статьи",
	},
	{
		href: "/feedback",
		title: "Отзывы",
	},
	{
		href: "/contacts",
		title: "Контакты",
	},
];

export const INFO_GRID_ITEMS = [
	{
		title: "Более 360 брендов",
		text: "Производство Испании, Италии, Португалии, России, Германии, Китая и других стран",
	},
	{
		title: "Выставочные залы в Москве",
		text: "Северный зал — ул. Белозерская, 23Г, Восточный зал — ул. Салтыковская, 7Г, ТЦ Авалон",
	},
	{
		title: "Просматривайте коллекции в 3D",
		text: "На сайте вы сразу можете «примерить» понравившуюся коллекцию на любую планировку и рассчитать смету",
	},
	{
		title: "Работаем с 2009 года",
		text: "За годы работы мы собрали базу всемирноизвестных поставщиков с высочайшим качеством товара",
	},
	{
		title: "Доставляем по всей Стране",
		text: "Срок доставки по Москве от 1 до 3 рабочих дней. Возможен самовывоз. В регионы доставка любой транспортной компанией",
	},
	{
		title: "Оплачивайте удобным способом",
		text: "Наличный и безналичный рассчет, картой в магазине, на сайте после оформления заказа, через приложение Сбербанк",
	},
];

export const FOOTER_LINKS = {
	pages: [
		{
			title: "Заказ и оплата",
			href: "/order",
		},
		{
			title: "Доставка",
			href: "/delivery",
		},
		{
			title: "О нас",
			href: "/about",
		},
		{
			title: "Статьи",
			href: "/publications",
		},
		{
			title: "Отзывы",
			href: "/feedback",
		},
		{
			title: "Контакты",
			href: "/contacts",
		},
	],
	categories: [
		{
			title: "Плитка",
			href: "/keramicheskaya-plitka",
		},
		{
			title: "Керамогранит",
			href: "/keramogranit",
		},
		{
			title: "Мозаика",
			href: "/mozaika",
		},
		{
			title: "Ступени",
			href: "/stupeni",
		},
		{
			title: "Затирка",
			href: "/zatirka-dlya-plitki",
		},
		{
			title: "Другое",
			href: "/plitka-soputstvuiushchie-tovary",
		},
	],
};

export const ProductBadges = {
	onRequest: {
		status: ProductStatus.available,
		className: "bg-accentYellow",
		title: "Под заказ",
	},
	inStock: {
		status: ProductStatus.inStock,
		className: "bg-accentGreen",
		title: "Складская программа",
	},
	notOnSale: {
		status: "",
		className: "bg-darkGray",
		title: "Нет в наличии",
	},
};

export const ORDER_TABS = [
	{
		key: "metter",
		title: "м2",
	},
	{
		key: "unit",
		title: "шт.",
	},
	{
		key: "pack",
		title: "упаковка",
	},
];
