/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ApolloClientContext } from "@/libs/apolloClient";
import type { RepoMethodOptions } from "@/types";
import type {
	LazyQueryHookOptions,
	LazyQueryResultTuple,
	MutationHookOptions,
	MutationTuple,
	OperationVariables,
	QueryHookOptions,
	QueryOptions,
	QueryResult,
} from "@apollo/client";

import { createRepoReturn } from "./createRepoReturn";

interface RepoFactoryConfig<TVariables extends OperationVariables, TData> {
	hook: (
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		options: QueryHookOptions<TData, TVariables> | (QueryHookOptions<TData, TVariables> & any)
	) => QueryResult<TData, TVariables>;
}

interface MutationFactoryConfig<TVariables extends OperationVariables, TData> {
	hook: (options?: MutationHookOptions<TData, TVariables>) => MutationTuple<TData, TVariables>;
}

interface ServerRepoFactoryConfig<TVariables, TData> {
	ssrFunction: (
		options: Omit<QueryOptions<TVariables>, "query">,
		ctx?: ApolloClientContext
	) => Promise<{ props: { data: TData } }>;
}

interface LazyQueryFactoryConfig<TVariables extends OperationVariables, TData> {
	hook: (options?: LazyQueryHookOptions<TData, TVariables>) => LazyQueryResultTuple<TData, TVariables>;
}

const createClientRequest = <TVariables extends OperationVariables, TData>(
	config: RepoFactoryConfig<TVariables, TData>
) => {
	return (variables?: TVariables, options?: RepoMethodOptions<TData>) => {
		const result = config.hook({
			variables: variables || ({} as TVariables),
			...options,
		});

		return createRepoReturn(result);
	};
};

const createServerRequest = <TVariables, TData>(config: ServerRepoFactoryConfig<TVariables, TData>) => {
	return async (variables?: TVariables, ctx?: ApolloClientContext): Promise<TData> => {
		const { props } = await config.ssrFunction({ variables: variables || ({} as TVariables) }, ctx);
		return props.data;
	};
};

const createMutation = <TVariables extends OperationVariables, TData>(
	config: MutationFactoryConfig<TVariables, TData>
) => {
	return (options?: MutationHookOptions<TData, TVariables>) => {
		const [mutate, result] = config.hook(options);
		return { mutate, result };
	};
};

const createLazyQuery = <TVariables extends OperationVariables, TData>(
	config: LazyQueryFactoryConfig<TVariables, TData>
) => {
	return (options?: LazyQueryHookOptions<TData, TVariables>) => {
		const [execute, result] = config.hook(options);
		return { execute, result };
	};
};

const RepoBuilder = {
	createClientRequest,
	createServerRequest,
	createMutation,
	createLazyQuery,
};

export default RepoBuilder;
